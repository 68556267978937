import * as Sentry from "@sentry/browser";
import * as SentryVue from "@sentry/vue";

/**
 * This module configures Sentry for error and performance tracking in applications.
 * It primarily uses @sentry/browser for the general Sentry setup suitable for any JavaScript application.
 * Additionally, for Vue-specific instances, it employs @sentry/vue functionalities (like mixins and error handlers)
 * to enhance error tracking and performance monitoring within Vue components.
 * This setup ensures that Sentry is initialized only once across the application,
 * while still providing tailored error handling and reporting for Vue-based parts of the application.
 */
const debug = false; // Set to true to enable console logging for debugging purposes

export function setupSentry(app) {
  const htmlElement = document.documentElement;
  const config = {
    dsn: htmlElement.dataset.sentryPublicDsn,
    environment: htmlElement.dataset.environment,
    release: htmlElement.dataset.release,
    userId: htmlElement.dataset.userId,
    instanceCode: htmlElement.dataset.instanceCode,
  };

  if (config.dsn) {
    // Initialize Sentry if it has not been initialized yet
    if (!Sentry.getCurrentHub().getClient()) {
      if (debug) {
        console.log("Sentry initing...", config);
      }
      Sentry.init({
        dsn: config.dsn,
        environment: config.environment, // Environment setting (e.g., 'production', 'development')
        release: config.release,
      });
    } else {
      if (debug) {
        console.log("Sentry already initialized");
      }
    }

    // Set the instance code in Sentry's scope if available
    if (config.instanceCode) {
      Sentry.configureScope((scope) => {
        scope.setTag("instance_code", config.instanceCode);
      });
    }

    // Set the user's ID in Sentry's scope if available
    if (config.userId) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: config.userId });
      });
    }

    // Attach Vue-specific features to the Vue app
    if (app) {
      if (debug) {
        console.log("Attaching Sentry to Vue app", app);
      }
      // Note: We are not re-initializing Sentry here, just attaching Vue-specific functionalities
      app.mixin(SentryVue.createTracingMixins({ trackComponents: true }));
      SentryVue.attachErrorHandler(app, { logErrors: true });
    }
  } else {
    if (debug) {
      console.log("Sentry not configured");
    }
  }
}
